/**
 * @description
 * 앰플리튜드 이벤트 목록 정리
 */

/****************************************************************************************
 * 페이지 단위로 분류
 ****************************************************************************************/

/**
 * 메인 홈 페이지
 */
/** @description 홈 화면 페이지 뷰 이벤트 */
export const PAGEVIEW_HOME = 'pageview_home';
/** @description 홈 화면 스크롤 이벤트 */
export const SCROLL_HOME = 'scroll_home';
/** @description 홈 화면 배너 클릭 이벤트 */
export const CLICK_EVENT_BANNER = 'click_event_banner';
/** @description 홈 화면 > 선생님 큐레이션 클릭 이벤트 */
export const CLICK_TEACHER_CURATION = 'click_teacher_curation';
/** @description 홈 > 학습 > 학년별 교육과정 아이템 클릭 이벤트 */
export const CLICK_GRADE_EDUCATION = 'click_grade_education';
/** @description 홈 > 재능 > 후기 클릭 이벤트 */
export const CLICK_REVIEW_ITEM = 'click_review_item';

/**
 * 수업 상세 페이지
 */
/** @description 수업 상세페이지 뷰 이벤트 */
export const PAGEVIEW_PRODUCT = 'pageview_product';
/** @description 수업 상세페이지 상세정보 더보기 클릭 이벤트 */
export const CLICK_PRODUCT_DETAIL = 'click_product_detail';
/** @description 수업 curation 페이지 뷰 이벤트 */
export const PAGEVIEW_CURATION = 'pageview curation';
/** @description 수업 스케줄 정원초과거나 스케줄의 맨 마지막 부분에 보이는 검색 페이지 이동 버튼 클릭 이벤트 */
export const CLICK_SEARCH_MORE = 'click_search_more';
/** @description 수업 스케줄에 VOD가 있는 경우 VOD 링크 버튼 클릭 이벤트 */
export const CLICK_SCHEDULE_VOD = 'click_schedule_vod';
/** @description 수업 상세페이지 리뷰 보러가기(앵커) 클릭 이벤트 */
export const CLICK_MORE_REVIEW = 'click_more_review';
/** @description 수업 상세페이지 리뷰 더보기 클릭 이벤트 */
export const CLICK_LOAD_REVIEW = 'click_load_review';
/** @description 수업 상세 스케줄 선택 이벤트 */
export const CLICK_SCHEDULE = 'click_schedule';
/** @description 수업 결제 페이지 이동하기 위한 클릭 이벤트 */
export const INITIATE_CHECKOUT = 'initiate_checkout';
/** @description 수업 상세 수업별 선생님의 공지 클릭 이벤트 */
export const CLICK_TEACHER_PRODUCT_NOTICE = 'click_teacher_product_notice';

/**
 * 회원 가입 페이지
 */
/** @description 회원가입 페이지 진입 로그 */
export const INITIATE_SIGNUP = 'initiate_signup';
/** @description 회원가입 완료 페이지 > 성공 로그 */
export const COMPLETE_SIGNUP = 'complete_signup';
/** @description 회원가입 완료 페이지 > 이벤트 전환 클릭 (결제페이지나 심리와진로의 경우 해설영상 이어서 보기 등) */
export const VIEW_MODAL = 'view_modal';

/**
 * 스케줄 상세 페이지
 */
/** @description 내 스케줄 페이지 뷰 이벤트 */
export const PAGEVIEW_SCHEDULE_LIST = 'pageview_schedule_list';
/** @description 내 스케줄 상세 페이지 뷰 이벤트 */
export const PAGEVIEW_SCHEDULE = 'pageview schedule';

/**
 * 심리와 진로
 */
/** @description 심리와 진로 > 페이지뷰 이벤트 */
export const PAGEVIEW_PSYCHE = 'pageview_psyche';
/** @description 심리와 진로 > 회원 가입 퍼널 */
export const CLICK_SIGNUP_PSYCHE = 'click_signup_psyche';
/** @description 심리와 진로 > 뒤로가기 버튼 클릭 이벤트 */
export const CLICK_PREVIOUS = 'click_previous';
/** @description 심리와 진로 > 쿠폰 받기 클릭 */
export const CLICK_COUPON_PSYCHE = 'click_coupon_psyche';
/** @description 심리와 진로 > 휴노 > 카데고리 칩 클릭 이벤트 */
export const CLICK_CATEGORY_PSYCHE = 'click_category_psyche';
/** @description 심리와 진로 > 가이던스 > 관련 수업 더 찾아보기 버튼 클릭 이벤트 */
export const CLICK_MORE = 'click_more';

/**
 * 블로그 페이지 이벤트
 */
/** @description 블로그 > 카테고리 탭 클릭시 찍는 이벤트 */
export const CLICK_BLOG_CATEGORY = 'click_blog_category';
/** @description 블로그 > 블로그 메인 페이지 뷰 이벤트 */
export const PAGEVIEW_BLOG_LIST = 'pageview_blog_list';
/** @description 블로그 > 블로그 아이템 클릭해 상세페이지로 가는 액션 이벤트 */
export const CLICK_BLOG = 'click_blog';
/** @description 블로그 > 블로그 공유하기 이벤트 */
export const CLICK_BLOG_SHARE = 'click_blog_share';
/** @description 블로그 > 상세페이지 뷰 이벤트 */
export const PAGEVIEW_BLOG = 'pageview_blog';
/** @description 블로그 > 상세페이지 스크롤 이벤트 */
export const SCROLL_BLOG = 'scroll_blog';

/**
 * 소개 페이지 이벤트
 */
/** @description 소개 페이지 뷰 이벤트 */
export const PAGEVIEW_INTRODUCTION = 'pageview introduction';

/**
 * 내 정보 페이지 & 하위 페이지 이벤트
 */
/** @description 내 정보 페이지 뷰 이벤트 */
export const PAGEVIEW_MYPAGE = 'pageview mypage';
/** @description 내 정보 > 결제정보 페이지 뷰 이벤트 */
export const PAGEVIEW_PURCHASE_HISTORY = 'pageview_purchase_history';
/** @description 내 정보 > 구독 현황 페이지 뷰 이벤트 */
export const PAGEVIEW_MY_SUBSCRIPTION = 'pageview_my_subscription';

/**
 * 쿠폰 사용 가능한 아이템 모아보는 페이지 뷰 이벤트
 */
export const PAGEVIEW_USABLE_PRODUCT = 'pageview_usable_product';

/**
 * 선생님 > 검색페이지 & 상세페이지 이벤트
 */
/** @description 선생님 검색 페이지 뷰 이벤트 */
export const PAGEVIEW_TEACHER_LIST = 'pageview teacher list';
/** @description 선생님 상세 페이지 뷰 이벤트 */
export const PAGEVIEW_TEACHER = 'pageview teacher';
/** @description 선생님 상세 > 프라이빗 클래스 상담 신청 클릭 이벤트 */
export const CLICK_PRIVATE_APPLY = 'click_private_apply';

/**
 * 이벤트 페이지
 */
/** @description 이벤트 페이지 뷰 로그 */
export const PAGEVIEW_EVENT = 'pageview event';
/** @description 종료된 이벤트 페이지에서 뜨는 팝업으로 진행중인 다른 이벤트 보기 클릭시 이벤트 */
export const CLICK_CHECK_EVENTS = 'click_check_events';

/**
 * 구독 취소 페이지
 */
/** @description 구독 취소 시작 이벤트 */
export const INITIATE_CANCEL_SUBSCRIPTION = 'initiate_cancel_subscription';
/** @description 구독 취소 옵션 선택시 찍히는 이벤트 */
export const CLICK_CANCEL_SUBSCRIPTION_OPTION =
  'click_cancel_subscription_option';
/** @description 구독 취소 완료 이벤트 */
export const COMPLETE_CANCEL_SUBSCRIPTION = 'complete_cancel_subscription';

/**
 * 결제 취소 페이지
 */
/** @description 결제 취소 페이지 뷰 이벤트 */
export const PAGEVIEW_SCHEDULE_REFUND = 'pageview_schedule_refund';
/** @description 결제 취소 페이지 취소 클릭 이벤트 */
export const CLICK_REFUND = 'click_refund;';

/****************************************************************************************
 * 기능 단위 분리
 ****************************************************************************************/

/**
 * 미리보기 관련 이벤트
 */
/** @description 미리보기 최초 클릭시 이벤트 */
export const START_PREVIEW = 'start_preview';
/** @description 미리보기 플레이어가 destroy되기 전에 최종 상태를 찍는 이벤트 */
export const PREVIEW_PLAY_RECORD = 'preview_play_record';

/**
 * VOD PoC
  */
export const VOD_PLAY_RECORD = 'vod_play_record';
export const START_VOD = 'start_vod';
export const CLICK_SCHEDULE_TAB = 'click_schedule_tab';

/**
 * Chat 관련 이벤트
 */
/** @description 채팅 보내기 이벤트 */
export const CLICK_SEND_CHAT = 'click_send_chat';
/** @description 대화방 진입 이벤트 */
export const PAGEVIEW_CHAT_ROOM = 'pageview_chat_room';
/** @description 대화방에서 스케줄 기록 클릭 시 */
export const CLICK_SCHEDULE_HISTORY = 'click_schedule_history';
/** @description 질문하기 클릭시 */
export const INITIATE_CHAT_QUESTION = 'initiate_chat_question';

/**
 * Roadmap 관련 이벤트
 */
/** @description 로드맵 상세페이지 페이지뷰 이벤트 */
export const PAGEVIEW_ROADMAP = 'pageview_roadmap';
/** @description 로드맵 아이템 클릭 이벤트 */
export const CLICK_ROADMAP = 'click_roadmap';
/** @description 로드맵 찜하기 이벤트 */
export const ADD_FAVORITE_ROADMAP = 'add_favorite_roadmap';
/** @description 로드맵 영역이 그려지면 찍히는 이벤트 (아이템이 0개라 그려지지 않으면 찍히기 않습니다) */
export const LOAD_ROADMAP_SECTION = 'load_roadmap_section';

/**
 * Product 관련 이벤트
 */
/** @description product 아이템 클릭해 상세페이지로 가려는 액션 이벤트 */
export const CLICK_PRODUCT = 'click_product';
/** @description 검색 결과 내 product 아이템 클릭해 상세페이지로 가려는 액션 이벤트 */
export const CLICK_PRODUCT_SEARCH = 'click_product_search';

/**
 * 로그인 관련 이벤트
 */
/** @description 로그인 버튼 클릭 이벤트 */
export const CLICK_SIGNUP = 'click_signup';

/**
 * 찜하기 액션 관련 이벤트
 */
/** @description 찜하기 추가 액션 */
export const ADD_FAVORITE = 'add_favorite';
/** @description 알림신청 추가 액션 */
export const ADD_ALARM = 'add_alarm';

/**
 * 리뷰 관련 이벤트
 */
/** @description 리뷰 도움이 돼요 클릭 이벤트 */
export const CLICK_LIKE_REVIEW = 'click_like_review';
/** @description 리뷰 작성 하러가기 이벤트 */
export const INITIATE_REVIEW = 'initiate_review';
/** @description 리뷰 작성 완료 이벤트 */
export const COMPLETE_REVIEW = 'complete_review';

/**
 * 레이아웃 컴포넌트에 있는 이벤트들
 */
/** @description 상단 로그인 유저에게 보이는 메뉴 클릭시 발생하는 이벤트 */
export const CLICK_NAVIGATION = 'click_navigation';
/** @description drawer에 있는 메뉴 클릭시 발생하는 이벤트 */
export const CLICK_NAV_MENU = 'click nav-menu';
/** @description 앱 다운로드 링크 클릭 이벤트 */
export const CLICK_APP_DOWNLOAD = 'click_app_download';
/** @description 모바일 하단 네비게이션 메뉴 클릭 이벤트 */
export const CLICK_TABBAR = 'click_tabbar';
/** @description 홈 화면 > 상단 재능/학습 전환 스위치 클릭 이벤트 */
export const CLICK_HOMETYPE = 'click_hometype';
/** @description 푸터 클릭 이벤트 */
export const CLICK_FOOTER = 'click_footer';
/** @description 홈 네비게이션 아래 붙는 키워드 배너 클릭 이벤트 */
export const CLICK_KEYWORD_BANNER = 'click_keyword_banner';

/**
 * 꾸그몰 관련 이벤트
 */
/** @description 푸터 하단 꾸그몰 클릭 이벤트 & 수업 상세페이지 교구재 구매 버튼 & 수업 결제피이지 교구재 구매 버튼 */
export const CLICK_GGUGEMALL = 'click_ggugemall';

/**
 * 카테고리 이벤트
 */
/** @description 카테고리 아이템 클릭 이벤트 */
export const CLICK_CATEGORY = 'click_category';

/**
 * 꾸그머니 & 결제 관련 이벤트
 */
/** @description drawer > 내 프로필 꾸그머니 충전 클릭 이벤트 & 내 머니 프로필에서 충전 클릭시 & 결제페이지에서 꾸그머니충전으로 진입시 */
export const INITIATE_MONEY_CHARGE = 'initiate_money_charge';
/** @description 수업 결제 페이지 뷰 이벤트 */
export const PAGEVIEW_PAYMENT_FORM = 'pageview_payment_form';
/** @description 수업 결제 페이지 > 결제하기 클릭 이벤트 */
export const CLICK_PAYMENT = 'click_payment';
/** @description 결제 완료 이벤트 로깅 (수업 & 꾸그머니) */
export const COMPLETE_PAYMENT = 'complete_payment';

/**
 * 선생님 관련 이벤트
 */
/** @description 선생님 상세페이지로 이동하는 클릭을 잡는 이벤트 */
export const CLICK_LOAD_TEACHER = 'click_load_teacher';
/** @description 선생님 정보 더보기 클릭 이벤트 */
export const CLICK_TEACHER_DETAIL = 'click_teacher_detail';

/**
 * 공유 관련 이벤트
 */
/** @description 공유하기 이벤트 */
export const CLICK_SHARE = 'click share';

/**
 * 검색 관련 이벤트
 */
/** @description 검색 시작 클릭 이벤트 */
export const INITIATE_SEARCH = 'initiate_search';
/** @description 검색 필터 초기화 버튼 클릭 이벤트 */
export const FILTER_RESET = 'filter_reset';
/** @description 검색 필터 적용 버튼 클릭 이벤트 */
export const APPLY_FILTER = 'apply_filter';
/** @description 검색 완료 이벤트 */
export const PAGEVIEW_SEARCH_RESULT = 'pageview_search_result';
/** @description 검색 키워드 > 인기 검색어 클릭 이벤트 */
export const CLICK_BEST_KEYWORD = 'click best keyword';
/** @description 검색 키워드 > 추천 키워드 클릭 이벤트 */
export const CLICK_RECOMMEND_KEYWORD = 'click recommend keyword';

/**
 * 큐레이션 관련 이벤트
 */
/** @description 전체보기 클릭 이벤트 */
export const CLICK_VIEW_ALL = 'click_view_all';

/**
 * 쿠폰 관련 이벤트
 */
/** @description 쿠폰 등록 성공 이벤트 */
export const COMPLETE_COUPON = 'complete_coupon';

/**
 * 랭킹 이벤트 관련
 */
/** @description 홈 랭킹 섹션의 개별 랭킹 탭 클릭 */
export const CLICK_RANKING_TAB = 'click_ranking_tab';
/** @description 홈 랭킹 섹션 타이틀 옆 전체보기 클릭 */
export const CLICK_RANKING_ALL = 'click_ranking_all';
/** @description 홈 랭킹 섹션의 각 랭킹별 4페이지에서 더보기 클릭 */
export const CLICK_RANKING_MORE = 'click_ranking_more';
/** @description 홈 랭킹 섹션의 페이지가 넘어간 경우 View이벤트 */
export const VIEW_RANKING_PAGE = 'view_ranking_page';
/** @description 랭킹 페이지 진입 */
export const PAGEVIEW_RANKING_ALL = 'pageview_ranking_all';
/** @description 홈 랭킹섹션, 랭킹 페이지 내에서 개별 수업 클릭 */
export const CLICK_PRODUCT_RANKING = 'click_product_ranking';
/** @description 랭킹 페이지의 재능/학습 토글 클릭  */
export const CLICK_RANKING_LEARNING_TYPE = 'click_ranking_learning_type';
/** @description 랭킹 페이지 내 랭킹 기간 설정 변경 시  */
export const CLICK_RANKING_TERM = 'click_ranking_term';
/** @description 랭킹 페이지 내 상위 랭킹 클릭  */
export const CLICK_MAIN_RANKING = 'click_main_ranking';
/** @description 랭킹 페이지 내 하위 랭킹 클릭  */
export const CLICK_SUB_RANKING = 'click_sub_ranking';

/**
 * ETC
 */
/** @description 특정 섹션 뷰 이벤트 */
export const VIEW_SECTION = 'view_section';
/** @description 스케줄 참여 클릭 이벤트 */
export const CLICK_ATTEND_CLASS = 'click_attend_class';

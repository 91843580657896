export const state = () => ({
  recommendedKeywordList: [],
  bestKeywordList: [],
});

export const getters = {
  recommendedKeywordList: state => state.recommendedKeywordList,
  bestKeywordList: state => state.bestKeywordList,
};

export const actions = {
  selectBestKeywordList({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/class/search/popular-keyword`)
        .then(res => {
          commit('SET_BEST_KEYWORD_LIST', {
            best: res.data.map(keyword => ({ keyword })),
          });
          resolve(res);
        })
        .catch(reject);
    });
  },
  selectRecommendKeywordList({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/class/keyword?hasLink=false`)
        .then(res => {
          commit('SET_RECOMMEND_KEYWORD_LIST', {
            recommends: res.data.filter(k => k.isRecommended),
          });
          resolve(res);
        })
        .catch(reject);
    });
  },
};

export const mutations = {
  SET_BEST_KEYWORD_LIST(state, { best }) {
    state.bestKeywordList = best;
  },

  SET_RECOMMEND_KEYWORD_LIST(state, { recommends }) {
    state.recommendedKeywordList = recommends.sort((a, b) =>
      a.weight < b.weight ? 1 : -1,
    );
  },
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7afd51cb = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _7295ebfe = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _664aa252 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _4abc3567 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _7d4fc080 = () => interopDefault(import('../pages/introduction.vue' /* webpackChunkName: "pages/introduction" */))
const _7f5fb85d = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _1a56b6d5 = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _7e3e8201 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _acfa56e0 = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _8833b608 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _571b2170 = () => interopDefault(import('../pages/psych/index.vue' /* webpackChunkName: "pages/psych/index" */))
const _bcea2a9a = () => interopDefault(import('../pages/psyche/index.vue' /* webpackChunkName: "pages/psyche/index" */))
const _66c010fa = () => interopDefault(import('../pages/ranking/index.vue' /* webpackChunkName: "pages/ranking/index" */))
const _723cfa4e = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _1113a3ee = () => interopDefault(import('../pages/roadmap/index.vue' /* webpackChunkName: "pages/roadmap/index" */))
const _19c66100 = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _7d4de65e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _f18b5fa2 = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _4a3e5bb6 = () => interopDefault(import('../pages/auth/leave.vue' /* webpackChunkName: "pages/auth/leave" */))
const _61f81428 = () => interopDefault(import('../pages/auth/leave-success.vue' /* webpackChunkName: "pages/auth/leave-success" */))
const _38f5f2ae = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _19962d7c = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _7baf48f2 = () => interopDefault(import('../pages/card/fail.vue' /* webpackChunkName: "pages/card/fail" */))
const _1b127674 = () => interopDefault(import('../pages/card/result.vue' /* webpackChunkName: "pages/card/result" */))
const _31386f52 = () => interopDefault(import('../pages/my/alarm/index.vue' /* webpackChunkName: "pages/my/alarm/index" */))
const _1097a885 = () => interopDefault(import('../pages/my/authCode/index.vue' /* webpackChunkName: "pages/my/authCode/index" */))
const _2aee00b3 = () => interopDefault(import('../pages/my/coupon.vue' /* webpackChunkName: "pages/my/coupon" */))
const _bfd84946 = () => interopDefault(import('../pages/my/favorites/index.vue' /* webpackChunkName: "pages/my/favorites/index" */))
const _222f4f58 = () => interopDefault(import('../pages/my/friends.vue' /* webpackChunkName: "pages/my/friends" */))
const _76a07006 = () => interopDefault(import('../pages/my/money/index.vue' /* webpackChunkName: "pages/my/money/index" */))
const _6c917d14 = () => interopDefault(import('../pages/my/order/index.vue' /* webpackChunkName: "pages/my/order/index" */))
const _664a2aef = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _6ce6aee1 = () => interopDefault(import('../pages/my/reviews/index.vue' /* webpackChunkName: "pages/my/reviews/index" */))
const _b2f31d26 = () => interopDefault(import('../pages/my/subscription/index.vue' /* webpackChunkName: "pages/my/subscription/index" */))
const _7b525ff1 = () => interopDefault(import('../pages/my/voucher.vue' /* webpackChunkName: "pages/my/voucher" */))
const _2ee992ad = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _33c6ca6c = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _1f714e5e = () => interopDefault(import('../pages/private/banner.vue' /* webpackChunkName: "pages/private/banner" */))
const _7ff3bd5a = () => interopDefault(import('../pages/private/commonTextAreaField.vue' /* webpackChunkName: "pages/private/commonTextAreaField" */))
const _06a4f36b = () => interopDefault(import('../pages/private/complete.vue' /* webpackChunkName: "pages/private/complete" */))
const _274d184b = () => interopDefault(import('../pages/private/preGuide.vue' /* webpackChunkName: "pages/private/preGuide" */))
const _5029e6bc = () => interopDefault(import('../pages/private/privateRequestForm.vue' /* webpackChunkName: "pages/private/privateRequestForm" */))
const _24d8349e = () => interopDefault(import('../pages/private/processGuideModal.vue' /* webpackChunkName: "pages/private/processGuideModal" */))
const _224533a4 = () => interopDefault(import('../pages/product/suggest.vue' /* webpackChunkName: "pages/product/suggest" */))
const _3cc2036c = () => interopDefault(import('../pages/psyche/guidance/index.vue' /* webpackChunkName: "pages/psyche/guidance/index" */))
const _66175300 = () => interopDefault(import('../pages/psyche/huno/index.vue' /* webpackChunkName: "pages/psyche/huno/index" */))
const _0bce8f1b = () => interopDefault(import('../pages/teacher/search.vue' /* webpackChunkName: "pages/teacher/search" */))
const _bdaf4ccc = () => interopDefault(import('../pages/my/money/fail.vue' /* webpackChunkName: "pages/my/money/fail" */))
const _4d78bd7f = () => interopDefault(import('../pages/my/money/fill.vue' /* webpackChunkName: "pages/my/money/fill" */))
const _116f8a19 = () => interopDefault(import('../pages/my/money/result.vue' /* webpackChunkName: "pages/my/money/result" */))
const _08afa586 = () => interopDefault(import('../pages/my/money/withdraw.vue' /* webpackChunkName: "pages/my/money/withdraw" */))
const _16371574 = () => interopDefault(import('../pages/my/money/withdraw-result.vue' /* webpackChunkName: "pages/my/money/withdraw-result" */))
const _7868f72b = () => interopDefault(import('../pages/my/order/cancel/index.vue' /* webpackChunkName: "pages/my/order/cancel/index" */))
const _de807b8e = () => interopDefault(import('../pages/my/profile/child.vue' /* webpackChunkName: "pages/my/profile/child" */))
const _bffbc69c = () => interopDefault(import('../pages/my/subscription/cancel/index.vue' /* webpackChunkName: "pages/my/subscription/cancel/index" */))
const _2217d39b = () => interopDefault(import('../pages/my/subscription/confirm.vue' /* webpackChunkName: "pages/my/subscription/confirm" */))
const _e69fe400 = () => interopDefault(import('../pages/schedule/cancel/result.vue' /* webpackChunkName: "pages/schedule/cancel/result" */))
const _2284841c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _bdc91d68 = () => interopDefault(import('../pages/my/reviews/_id/index.vue' /* webpackChunkName: "pages/my/reviews/_id/index" */))
const _588435b4 = () => interopDefault(import('../pages/product/curation/_id.vue' /* webpackChunkName: "pages/product/curation/_id" */))
const _402f45fc = () => interopDefault(import('../pages/psyche/guidance/_slug.vue' /* webpackChunkName: "pages/psyche/guidance/_slug" */))
const _69849590 = () => interopDefault(import('../pages/psyche/huno/_slug.vue' /* webpackChunkName: "pages/psyche/huno/_slug" */))
const _3654b193 = () => interopDefault(import('../pages/my/reviews/_id/result.vue' /* webpackChunkName: "pages/my/reviews/_id/result" */))
const _7971edf4 = () => interopDefault(import('../pages/my/order/_type/_id.vue' /* webpackChunkName: "pages/my/order/_type/_id" */))
const _7946b083 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _4905941f = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _47344762 = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _3051deae = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _b91d9338 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _4e273c32 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _3b28744a = () => interopDefault(import('../pages/product/_id/payment.vue' /* webpackChunkName: "pages/product/_id/payment" */))
const _3dec13df = () => interopDefault(import('../pages/product/_id/subscribe.vue' /* webpackChunkName: "pages/product/_id/subscribe" */))
const _485e0676 = () => interopDefault(import('../pages/product/_id/notice/_noticeId.vue' /* webpackChunkName: "pages/product/_id/notice/_noticeId" */))
const _5a886400 = () => interopDefault(import('../pages/psych/_slug.vue' /* webpackChunkName: "pages/psych/_slug" */))
const _74cbc29e = () => interopDefault(import('../pages/roadmap/_id.vue' /* webpackChunkName: "pages/roadmap/_id" */))
const _4c071c74 = () => interopDefault(import('../pages/roadmap/_id/index.vue' /* webpackChunkName: "pages/roadmap/_id/index" */))
const _31791b1b = () => interopDefault(import('../pages/schedule/_hash.vue' /* webpackChunkName: "pages/schedule/_hash" */))
const _d20ccc16 = () => interopDefault(import('../pages/schedule/_hash/detail.vue' /* webpackChunkName: "pages/schedule/_hash/detail" */))
const _9fa6db52 = () => interopDefault(import('../pages/teacher/_id.vue' /* webpackChunkName: "pages/teacher/_id" */))
const _5ed2ac3c = () => interopDefault(import('../pages/coupon/_id/usable-product.vue' /* webpackChunkName: "pages/coupon/_id/usable-product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _7afd51cb,
    name: "blog"
  }, {
    path: "/chat",
    component: _7295ebfe,
    name: "chat"
  }, {
    path: "/error",
    component: _664aa252,
    name: "error"
  }, {
    path: "/event",
    component: _4abc3567,
    name: "event"
  }, {
    path: "/introduction",
    component: _7d4fc080,
    name: "introduction"
  }, {
    path: "/maintenance",
    component: _7f5fb85d,
    name: "maintenance"
  }, {
    path: "/my",
    component: _1a56b6d5,
    name: "my"
  }, {
    path: "/notice",
    component: _7e3e8201,
    name: "notice"
  }, {
    path: "/private",
    component: _acfa56e0,
    name: "private"
  }, {
    path: "/product",
    component: _8833b608,
    name: "product"
  }, {
    path: "/psych",
    component: _571b2170,
    name: "psych"
  }, {
    path: "/psyche",
    component: _bcea2a9a,
    name: "psyche"
  }, {
    path: "/ranking",
    component: _66c010fa,
    name: "ranking"
  }, {
    path: "/request",
    component: _723cfa4e,
    name: "request"
  }, {
    path: "/roadmap",
    component: _1113a3ee,
    name: "roadmap"
  }, {
    path: "/schedule",
    component: _19c66100,
    name: "schedule"
  }, {
    path: "/search",
    component: _7d4de65e,
    name: "search"
  }, {
    path: "/teacher",
    component: _f18b5fa2,
    name: "teacher"
  }, {
    path: "/auth/leave",
    component: _4a3e5bb6,
    name: "auth-leave"
  }, {
    path: "/auth/leave-success",
    component: _61f81428,
    name: "auth-leave-success"
  }, {
    path: "/auth/signup",
    component: _38f5f2ae,
    name: "auth-signup"
  }, {
    path: "/auth/success",
    component: _19962d7c,
    name: "auth-success"
  }, {
    path: "/card/fail",
    component: _7baf48f2,
    name: "card-fail"
  }, {
    path: "/card/result",
    component: _1b127674,
    name: "card-result"
  }, {
    path: "/my/alarm",
    component: _31386f52,
    name: "my-alarm"
  }, {
    path: "/my/authCode",
    component: _1097a885,
    name: "my-authCode"
  }, {
    path: "/my/coupon",
    component: _2aee00b3,
    name: "my-coupon"
  }, {
    path: "/my/favorites",
    component: _bfd84946,
    name: "my-favorites"
  }, {
    path: "/my/friends",
    component: _222f4f58,
    name: "my-friends"
  }, {
    path: "/my/money",
    component: _76a07006,
    name: "my-money"
  }, {
    path: "/my/order",
    component: _6c917d14,
    name: "my-order"
  }, {
    path: "/my/profile",
    component: _664a2aef,
    name: "my-profile"
  }, {
    path: "/my/reviews",
    component: _6ce6aee1,
    name: "my-reviews"
  }, {
    path: "/my/subscription",
    component: _b2f31d26,
    name: "my-subscription"
  }, {
    path: "/my/voucher",
    component: _7b525ff1,
    name: "my-voucher"
  }, {
    path: "/payment/fail",
    component: _2ee992ad,
    name: "payment-fail"
  }, {
    path: "/payment/result",
    component: _33c6ca6c,
    name: "payment-result"
  }, {
    path: "/private/banner",
    component: _1f714e5e,
    name: "private-banner"
  }, {
    path: "/private/commonTextAreaField",
    component: _7ff3bd5a,
    name: "private-commonTextAreaField"
  }, {
    path: "/private/complete",
    component: _06a4f36b,
    name: "private-complete"
  }, {
    path: "/private/preGuide",
    component: _274d184b,
    name: "private-preGuide"
  }, {
    path: "/private/privateRequestForm",
    component: _5029e6bc,
    name: "private-privateRequestForm"
  }, {
    path: "/private/processGuideModal",
    component: _24d8349e,
    name: "private-processGuideModal"
  }, {
    path: "/product/suggest",
    component: _224533a4,
    name: "product-suggest"
  }, {
    path: "/psyche/guidance",
    component: _3cc2036c,
    name: "psyche-guidance"
  }, {
    path: "/psyche/huno",
    component: _66175300,
    name: "psyche-huno"
  }, {
    path: "/teacher/search",
    component: _0bce8f1b,
    name: "teacher-search"
  }, {
    path: "/my/money/fail",
    component: _bdaf4ccc,
    name: "my-money-fail"
  }, {
    path: "/my/money/fill",
    component: _4d78bd7f,
    name: "my-money-fill"
  }, {
    path: "/my/money/result",
    component: _116f8a19,
    name: "my-money-result"
  }, {
    path: "/my/money/withdraw",
    component: _08afa586,
    name: "my-money-withdraw"
  }, {
    path: "/my/money/withdraw-result",
    component: _16371574,
    name: "my-money-withdraw-result"
  }, {
    path: "/my/order/cancel",
    component: _7868f72b,
    name: "my-order-cancel"
  }, {
    path: "/my/profile/child",
    component: _de807b8e,
    name: "my-profile-child"
  }, {
    path: "/my/subscription/cancel",
    component: _bffbc69c,
    name: "my-subscription-cancel"
  }, {
    path: "/my/subscription/confirm",
    component: _2217d39b,
    name: "my-subscription-confirm"
  }, {
    path: "/schedule/cancel/result",
    component: _e69fe400,
    name: "schedule-cancel-result"
  }, {
    path: "/",
    component: _2284841c,
    name: "index"
  }, {
    path: "/my/reviews/:id",
    component: _bdc91d68,
    name: "my-reviews-id"
  }, {
    path: "/product/curation/:id?",
    component: _588435b4,
    name: "product-curation-id"
  }, {
    path: "/psyche/guidance/:slug",
    component: _402f45fc,
    name: "psyche-guidance-slug"
  }, {
    path: "/psyche/huno/:slug",
    component: _69849590,
    name: "psyche-huno-slug"
  }, {
    path: "/my/reviews/:id/result",
    component: _3654b193,
    name: "my-reviews-id-result"
  }, {
    path: "/my/order/:type/:id?",
    component: _7971edf4,
    name: "my-order-type-id"
  }, {
    path: "/blog/:slug",
    component: _7946b083,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _4905941f,
    children: [{
      path: "",
      component: _47344762,
      name: "event-slug"
    }]
  }, {
    path: "/notice/:id",
    component: _3051deae,
    name: "notice-id"
  }, {
    path: "/product/:id",
    component: _b91d9338,
    children: [{
      path: "",
      component: _4e273c32,
      name: "product-id"
    }, {
      path: "payment",
      component: _3b28744a,
      name: "product-id-payment"
    }, {
      path: "subscribe",
      component: _3dec13df,
      name: "product-id-subscribe"
    }, {
      path: "notice/:noticeId?",
      component: _485e0676,
      name: "product-id-notice-noticeId"
    }]
  }, {
    path: "/psych/:slug",
    component: _5a886400,
    name: "psych-slug"
  }, {
    path: "/roadmap/:id",
    component: _74cbc29e,
    children: [{
      path: "",
      component: _4c071c74,
      name: "roadmap-id"
    }]
  }, {
    path: "/schedule/:hash",
    component: _31791b1b,
    name: "schedule-hash",
    children: [{
      path: "detail",
      component: _d20ccc16,
      name: "schedule-hash-detail"
    }]
  }, {
    path: "/teacher/:id",
    component: _9fa6db52,
    name: "teacher-id"
  }, {
    path: "/coupon/:id?/usable-product",
    component: _5ed2ac3c,
    name: "coupon-id-usable-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

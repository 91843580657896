const _COUPON_USABLE_ORDER_LIST = [
  { value: 'discount', text: '할인가 높은순' },
  { value: 'expire', text: '만료 임박순' },
  { value: 'register', text: '최근 등록순' },
];

const _INITIAL_COUPON = {
  couponId: -1,
  userCouponId: -1,
  title: null,
  discountPrice: 0,
  adjustDiscountPrice: 0,
  overDiscount: false,
  usable: false,
  issueDate: '',
  expireDate: '',
};

const _initSubscribeCouponStoreState = () => {
  return {
    selectedOrder: _COUPON_USABLE_ORDER_LIST[0]?.value || 'discount',
    loadState: {
      isPending: false,
      isError: false,
      errorMessage: '',
    },
    selectedSubscription: {},
    isCouponModalOpen: false,
    appliedSubscriptionCouponID: -1, // 적용되어있는 쿠폰의 유저쿠폰아이디
    selectedCoupon: Object.assign({}, _INITIAL_COUPON), // 선택한 쿠폰
  };
};

export const state = () => _initSubscribeCouponStoreState();

export const getters = {
  selectedOrder: ({ selectedOrder }) => selectedOrder,
  loadState: ({ loadState }) => loadState,
  isCouponModalOpen: ({ isCouponModalOpen }) => isCouponModalOpen,
  appliedSubscriptionCouponID: ({ appliedSubscriptionCouponID }) =>
    appliedSubscriptionCouponID,
  selectedCoupon: ({ selectedCoupon }) => selectedCoupon,

  // api 에러 관련
  isPending: state => state.loadState.isPending,
  isError: state => state.loadState.isError,
  errorMessage: state => state.loadState.errorMessage,

  selectedSubscription: state => state.selectedSubscription,
  couponOrderList: () => _COUPON_USABLE_ORDER_LIST,
};

export const actions = {
  // 내 구독 상품 목록 조회 v3
  selectMySubscriptionList(_, { page, limit, subscribeStatus, order }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/class/subscribe`, {
          params: {
            page,
            limit,
            subscribeStatus,
            order,
          },
        })
        .then(res => resolve(res.data))
        .catch(reject);
    });
  },
  /**
   * @description
   * subscribeId로 이 구독이 다음 스케줄을 결제했는지와, 다음 수업 날짜 데이터를 받습니다.
   * 구독 취소하러가기 전 다음 수업을 결제한 이력이 있는지 체크해 환불+취소일지 취소만 할지 결정하는데 사용.
   * @param {string} subscribeId 구독 아이디
   * @returns 구독의 다음 스케줄 결제 여부와 수업 날짜 데이터
   * {
   *  isPaid: true,
   *  nextClassDate: '2023-06-28',
   * }
   */
  selectUserSubscriptionCheckPaid(_, { subscribeId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/check-paid`,
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 주문: 구독 정보 조회
   * 구독을 해지 하는데 필요한 데이터들을 받아오는 API
   * @param {string} subscribeId 구독 아이디
   * @returns
   * classTitle, endDate, childName, subscriptionPeriod, cardName, cardNumber
   */
  selectUserSubscriptionCancelWait(_, { subscribeId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/cancel-wait`,
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 구독만 취소 (환불 받지않고)
   * @param {string} subscribeId 구독 아이디
   * @param {string} cancelCode 구독 취소 사유 CODE
   * @param {string} cancelReason 기타 사유시 추가
   */
  cancelSubscribe(_, { subscribeId, cancelCode, cancelReason }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/cancel`,
          {
            cancelCode,
            cancelReason,
          },
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 구독 대기중 상태의 구독을 다시 구독상태로 변경하는 api
   * CANCEL_WAIT -> SUBSCRIBE로 status를 변경해줍니다.
   * @param {string} subscribeId 구독 아이디
   */
  restoreSubscribe(_, { subscribeId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/restore`,
        )
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },

  /**
   * @description
   * 특정 상품에 대한 사용자 쿠폰 내역 조회 api
   * https://dev-api.gguge.com/v3/user/swagger-ui/index.html#/%EC%82%AC%EC%9A%A9%EC%9E%90%20%EC%BF%A0%ED%8F%B0/%EC%82%AC%EC%9A%A9%EC%9E%90%20%EC%BF%A0%ED%8F%B0%20-%20%ED%8A%B9%EC%A0%95%20%EC%83%81%ED%92%88%EC%97%90%20%EB%8C%80%ED%95%9C%20%EC%82%AC%EC%9A%A9%EC%9E%90%20%EC%BF%A0%ED%8F%B0%20%EB%82%B4%EC%97%AD%20%EC%A1%B0%ED%9A%8C
   * v3 신규 버전으로 나와서 기존꺼 건들지않고 신규로 만들었습니다
   * data {
   *  usableCount: number,
   *  coupon: {
   *   couponId: number,
   *   userCouponId: number,
   *   title: string,
   *   issueDate: string,
   *   reason: string,
   *   overDiscount: boolean,
   *   usable: boolean,
   *   discountPrice: number,
   *   adjustPrice: number,
   *   expireDate: string,
   *  }
   * }
   */
  async getUserCouponListForThisSubscribe({ state, commit, dispatch }) {
    // early return
    if (state.loadState.isPending) return;

    const loadState = { isPending: true, isError: false, errorMessage: '' };
    commit('SET_LOAD_STATE', loadState);
    try {
      const subscribeId = state.selectedSubscription.id;

      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/user/coupon/check-product`,
        {
          params: {
            productId: state.selectedSubscription.class.id,
            subscribeId,
            order: state.selectedOrder,
          },
        },
      );

      const { coupons, usableCount } = data;

      const returnData = {
        coupons,
        usableCount,
      };

      // 선택한 구독 수업에 대기중인 쿠폰이 있다면 해당 데이터를 불러옵니다
      if (state.selectedSubscription.waitingCoupon) {
        const waitingCoupon = await dispatch('getSubscribeWaitingCoupon', {
          subscribeId,
        });
        // 이 구독에 사용하려고 대기중인 쿠폰이 있다면 맨 앞에 추가 & 사용가능한 쿠폰 수 증가
        if (waitingCoupon && waitingCoupon.userCouponId) {
          returnData.coupons = [
            {
              ...waitingCoupon,
              usable: true,
            },
            ...returnData.coupons,
          ];
          returnData.usableCount += 1;
        }
      }

      loadState.isPending = false;
      commit('SET_LOAD_STATE', loadState);

      return returnData || {};
    } catch (e) {
      console.error(e);
      loadState.isPending = false;
      loadState.isError = true;
      loadState.errorMessage =
        e.response?.data.message || '알 수 없는 오류가 발생했습니다.';
      commit('SET_LOAD_STATE', loadState);
      return [];
    }
  },
  /**
   * @description
   * 구독 갱신 예약 쿠폰 등록
   * 성공시 true, 실패시 false와 error message를 반환합니다.
   */
  async addSubscribeWaitingCoupon(_, { subscribeId, userCouponId }) {
    try {
      const res = await this.$axios.$post(
        `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/waiting-coupon`,
        {
          userCouponId,
        },
      );

      return { result: res.status === 'SUCCESS', message: res.message };
    } catch (e) {
      console.error(e, e.response?.data.message);
      return { result: false, message: e.response?.data.message };
    }
  },
  /**
   * @description
   * 구독 갱신에 예약 걸어둔 쿠폰 수정
   * 성공시 true, 실패시 false와 error message를 반환합니다.
   */
  async putSubscribeWaitingCoupon(_, { subscribeId, userCouponId }) {
    try {
      const res = await this.$axios.$put(
        `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/waiting-coupon`,
        {
          userCouponId,
        },
      );

      return { result: res.status === 'SUCCESS', message: res.message };
    } catch (e) {
      console.error(e, e.response?.data.message);
      return { result: false, message: e.response?.data.message };
    }
  },
  /**
   * @description
   * 구독 갱신에 예약 걸어둔 쿠폰 삭제
   * 성공시 true, 실패시 false와 error message를 반환합니다.
   */
  async deleteSubscribeWaitingCoupon(_, { subscribeId }) {
    try {
      const res = await this.$axios.$delete(
        `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/waiting-coupon`,
      );

      return { result: res.status === 'SUCCESS', message: res.message };
    } catch (e) {
      console.error(e, e.response?.data.message);
      return { result: false, message: e.response?.data.message };
    }
  },
  /**
   * @description
   * 구독 > 예약 쿠폰 조회
   */
  async getSubscribeWaitingCoupon({ commit }, { subscribeId }) {
    try {
      const res = await this.$axios.$get(
        `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/waiting-coupon`,
        {
          subscribeId,
        },
      );
      commit('SET_SELECTED_COUPON', res.data);
      return res.data;
    } catch (e) {
      console.error(e, e.response?.data.message);
      return null;
    }
  },
};

export const mutations = {
  // 스토어 전체 초기화.
  RESET_SUBSCRIBE_COUPON_STORE_STATE(state) {
    Object.assign(state, _initSubscribeCouponStoreState());
  },
  SET_SELECTED_ORDER(state, { order }) {
    state.selectedOrder = order;
  },
  SET_LOAD_STATE(state, loadState) {
    state.loadState = loadState;
  },
  SET_COUPON_MODAL_OPEN(state, isOpen) {
    state.isCouponModalOpen = isOpen;
  },
  SET_SELECTED_COUPON(state, coupon) {
    state.selectedCoupon = coupon;
  },
  RESET_SELECTED_COUPON(state) {
    state.selectedCoupon = Object.assign({}, _INITIAL_COUPON);
  },
  // 쿠폰 모달 열기 & 선택한 구독 정보 저장 & 선택한 쿠폰 번호가 있다면 저장
  OPEN_COUPON_MODAL(state, subscription) {
    state.isCouponModalOpen = true;
    state.selectedSubscription = subscription;
    state.appliedSubscriptionCouponID = subscription.waitingCoupon
      ? subscription.waitingCoupon.userCouponId
      : -1;
  },
  CLOSE_COUPON_MODAL(state) {
    state.isCouponModalOpen = false;
  },
};

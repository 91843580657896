
/**
 * @description
 * 신규 상품컴포넌트
 * curation이나 grid(바둑판배열) 스타일에 쓰여서 componentType을 받아서 너비를 조절할 수 있게 했습니다.
 */
import { ADD_FAVORITE } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { PRODUCT_STATUS } from '@/utils/enums.js';
import { getLastClickSection } from '@/utils/localStorageUtils.js';
import { getPathAt } from '@/utils/path';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CommonProfile from '@/components/common/CommonProfile.vue';
import CumulativeCount from './cumulative-count/CumulativeCount.vue';
import PriceInfo from './price/PriceInfo.vue';
import RatingAndFavorite from './rating-favorite/RatingAndFavorite.vue';
import TagList from './tag/TagList.vue';
import ThumbnailItem from './thumbnail/ThumbnailItem.vue';
import ProductItemTitle from './title/ProductItemTitle.vue';

export default {
  name: 'ProductItem',
  components: {
    ThumbnailItem,
    ProductItemTitle,
    TagList,
    CommonProfile,
    RatingAndFavorite,
    PriceInfo,
    CumulativeCount,
  },
  props: {
    componentType: { type: String, default: 'curation' }, // curation || grid || new-curation
    showTagList: { type: Boolean, default: true }, // 태그 부분을 보여줄 것인지 여부. 디폴트는 true
    showPrice: { type: Boolean, default: false }, // 가격 섹션을 보여줄 것인지 여부. 디폴트는 false
    showCumulativeCount: { type: Boolean, default: false }, // 누적 선택 갯수 보여줄지 여부. 디폴트는 false
    showRating: { type: Boolean, default: true }, // 별점과 찜하기를 보여줄것인지 여부. 디폴트는 true
    cumulativeCount: { type: Number, default: 0 },
    id: { type: Number, required: true },
    thumbnailImg: { type: String, default: null },
    title: { type: String, required: true },
    classType: { type: String, default: '' },
    classPrice: { type: Number, default: 0 },
    favoriteCount: { type: Number, default: -1 }, // v2 api에서 favoriteCount가 없는 경우가 있어서 기본값을 -1로 줍니다.
    discountRate: { type: Number, default: 0 },
    classAge: { type: String, default: '' },
    rating: { type: String, default: '0.0' },
    ratingCount: { type: Number, default: 0 },
    ratingAvg: { type: Number, default: 0 },
    teacher: { type: Object, default: () => ({}) },
    productStatus: { type: String, default: null },
    productStat: { type: Object, default: () => ({}) }, // 일부 api에서 찜한 숫자 내려오는 방식 레거시
    voteCount: { type: Number, default: 0 },
    isFull: { type: Boolean, default: false },
    hasSchedule: { type: Boolean, default: true },
    dimFilter: { type: String, default: null },
    classCount: { type: Number, default: 0 },
    useClassCountTag: { type: Boolean, default: false }, // tagList에 횟수 태그를 넣을것인지 여부. 디폴트는 false
    useStore: { type: Boolean, default: true }, // 스토어에서 삭제 유무
  },
  data() {
    return {
      isPending: false,
      // 찜하기 / 찜하기 해제시 favoriteCount를 실시간으로 변경하기 위한 Temp값
      favoriteCountTemp: -1,
    };
  },
  computed: {
    ...mapGetters('user/product', ['myProductList']),
    dimFilterText() {
      if (this.productStatus === PRODUCT_STATUS.ARCHIVE) return '판매 중지';
      if (this.isFull) return '전석 매진';
      if (!this.hasSchedule) return '스케줄 없음';
      if (this.dimFilter) return this.dimFilter;

      return null;
    },
    productClass: ({ componentType }) => {
      if (componentType === 'grid') return 'grid';
      if (componentType === 'curation') return 'curation';
      if (componentType === 'new-curation') return 'new-curation';

      return null;
    },
  },
  mounted() {
    this.favoriteCountTemp =
      this.productStat?.favoriteCount > -1
        ? this.productStat.favoriteCount
        : this.favoriteCount;
  },
  methods: {
    ...mapMutations({
      addFavorite: 'user/favorite/ADD_FAVORITE_PRODUCT',
      removeFavorite: 'user/favorite/REMOVE_FAVORITE_PRODUCT',
      removeAlarm: 'user/favorite/REMOVE_ALARM',
    }),
    ...mapActions('user/favorite', ['toggleProductFavorite']),
    // 찜하기 액션
    async favoriteEvent() {
      if (this.isPending) return;

      try {
        this.isPending = true;

        const res = await this.toggleProductFavorite({ productId: this.id });

        if (res?.isFavorite) {
          // 찜하기 성공시
          this.logFavoriteEvent();

          this.$toast.open({
            message:
              '<p class="font-caption-bold">찜한 수업에 추가되었어요.</p>',
            type: 'default',
            duration: 2000,
          });

          if (this.useStore) {
            this.addFavorite({
              classId: this.id,
              product: {
                id: this.id,
                thumbnailImg: this.thumbnailImg,
                title: this.title,
                classType: this.classType,
                classAge: this.classAge,
                classPrice: this.classPrice,
                discountRate: this.discountRate,
                ratingCount: this.ratingCount,
                rating: this.rating,
                teacher: this.teacher,
                productStatus: this.productStatus,
                favoriteCount: this.favoriteCount,
                isFull: this.isFull,
                hasSchedule: this.hasSchedule,
              },
            });
          }

          if (this.favoriteCountTemp > -1) this.favoriteCountTemp++;
        } else {
          if (this.useStore) {
            this.removeFavorite({ classId: this.id });
          } else {
            this.removeAlarm({ classId: this.id });
          }

          if (this.favoriteCountTemp > -1)
            this.favoriteCountTemp = Math.max(this.favoriteCountTemp - 1, 0);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isPending = false;
      }
    },
    logFavoriteEvent() {
      this.logUserCustomEvent(ADD_FAVORITE, {
        at: getPathAt(this.$route),
        product_id: this.id,
        component: 'card',
        section: getLastClickSection(),
        product_status: this.productStatus,
        class_type: this.classType,
      });

      this.setAmplitudeUserPropertiesOnce({
        first_favorite_date: this.getCurrentDateTimeFromKST(),
      });
    },
  },
};
